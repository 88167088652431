import React, { useEffect } from "react";
import "./index.scss";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import PostSession from "./PostSession";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { WEBSOCKET_CHANNEL, ACTION_NAME, PAGES } from "../../constants/options";
import socket from "../../helper/socket";
import { reqSetPage } from "../../reduxs/home/action";
import { actIntroduction } from "../../reduxs/scene/action";
import { reqSetIsShowUnitList, reqSetSelectedUnit } from "../../reduxs/unit-explore/action";
import { MAX, MIN } from "../unit-explore/UnitFilter";

const Session = ({ isPresentation, resetState, resetActiveColorModel, setActiveObjectIds }) => {
  const customerId = useQuery().get('customer');
  const history = useHistory();
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.END_POST_SESSION) {
          handleCloseSession();
        }
      });
    }
  }, []);

  const handleCloseSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.END_POST_SESSION);
    }
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    dispatch(unitExploreAct.reqFilterUnitLotSize({
        min: MIN,
        max: MAX,
      }));
    resetState();
    resetActiveColorModel({});
    setActiveObjectIds([]);
    dispatch(reqSetSelectedUnit(""));
    dispatch(reqSetIsShowUnitList(false));
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push(isPresentation ? 'presentation' : '/');
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  return (
    <div
      className="static-page-wf sessionPage"
      style={{ backgroundImage: `url(./images/bg-onboard.png)`, backgroundColor: "#fff" }}
    >
      <div className="scroll-view">
        <PostSession customerId={customerId} isPresentation={isPresentation} />
      </div>
      <div className="close-view">
        <span onClick={handleCloseSession}>
          CLOSE AND SAVE SESSION
        </span>
      </div>
    </div>
  );
};

export default Session;
